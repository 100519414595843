import React, { useState } from 'react';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import '../styles/Upload.css';

const genresList = ['Rock', 'Pop', 'Hip-Hop', 'Electronic', 'Jazz', 'Classical', 'Country', 'Blues'];

const Upload = () => {
  const [file, setFile] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [title, setTitle] = useState('');
  const [genre, setGenre] = useState('');
  const [trackType, setTrackType] = useState('');
  const [loading, setLoading] = useState(false); // State to track loading
  const auth = getAuth();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleCoverPhotoChange = (e) => {
    setCoverPhoto(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file || !title || !genre || !trackType) {
      alert("Please fill in all fields.");
      return;
    }
  
    const user = auth.currentUser;
    if (!user) {
      alert("User is not authenticated.");
      return;
    }
  
    const firebaseUid = user.uid;
  
    const formData = new FormData();
    formData.append('file', file);
    formData.append('title', title);
    formData.append('genre', genre);
    formData.append('trackType', trackType);
    formData.append('firebaseUid', firebaseUid);
    if (coverPhoto) {
      formData.append('coverPhoto', coverPhoto);
    }

    setLoading(true); // Start loading
  
    try {
      const response = await axios.post('https://sync-api-troz.onrender.com/api/uploads', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert("File and cover photo uploaded successfully!");
      console.log(response.data);
    } catch (error) {
      console.error('Error uploading file and cover photo:', error);

      if (
        error.response &&
        error.response.status === 403 &&
        error.response.data.message === 'Upload limit reached. Upgrade to premium to upload more tracks.'
      ) {
        alert("You have reached your upload limit for the freemium plan. Consider upgrading to premium to upload more tracks.");
      } else {
        alert("Error uploading file and cover photo.");
      }
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="upload-container">
      <h2>Upload Music</h2>
      <form onSubmit={handleSubmit} className="upload-form">
        <div className="form-group-upload">
          <label className='upload-label'>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="upload-input"
            id='title-upload'
          />
        </div>

        <div className="form-group-upload">
          <label className='upload-label'>Track Type</label>
          <select value={trackType} onChange={(e) => setTrackType(e.target.value)} required className="upload-input">
            <option value="">Select Track Type</option>
            <option value="vocals">Vocals</option>
            <option value="beat">Beat</option>
            <option value="both">Vocals & Beat</option>
          </select>
        </div>

        <div className="form-group-upload">
          <label className='upload-label'>Genre</label>
          <select value={genre} onChange={(e) => setGenre(e.target.value)} required className="upload-input">
            <option value="">Select Genre</option>
            {genresList.map((genre) => (
              <option key={genre} value={genre}>{genre}</option>
            ))}
          </select>
        </div>

        <div id='file-upload' className="form-group-upload">
          <label className='upload-label'>Upload File</label>
          <input type="file" accept=".mp3,.m4a" onChange={handleFileChange} />
        </div>

        <div id='cover-photo-upload' className="form-group-upload">
          <label className='upload-label'>Upload Cover Photo</label>
          <input type="file" accept=".jpg,.jpeg,.png" onChange={handleCoverPhotoChange} />
        </div>

        <button 
          type="submit" 
          className="upload-button" 
          disabled={loading} // Disable the button while loading
        >
          {loading ? 'Uploading...' : 'Upload'} {/* Show 'Uploading...' when loading */}
        </button>
      </form>

      {loading && <div className="loader"></div>} {/* Display loader while uploading */}
    </div>
  );
};

export default Upload;
